<template>
    <el-dialog title="录音播放" :visible.sync="dialogVisible" width="20%" :before-close="stop"
    :modal-append-to-body="false" append-to-body
    >
      <template>
        <center>
          <audio
            :src="src"
            autoplay="autoplay"
            controls="controls"
            ref="audio"
          >Your browser does not support the audio element.</audio>
        </center>
      </template>
    </el-dialog>
</template>

<script>
  export default {
  data() {
    return {
      src: "",
      dialogVisible: false,
    }
  },
 
  methods: {
    //播放组件
    handlePlay(filePath) {
      // this.dialogVisible = true
      this.src = filePath;
      this.play();

    },
    //播放
    play() {
      this.dialogVisible = true;
      // this.$refs.audio.play();
      // var audio = document.getElementById("audioPlay");
      // //audio.play();
      // var playPromise = audio.play();
 
      // if (playPromise) {
      //     playPromise.then(() => {
      //         // 音频加载成功
      //         // 音频的播放需要耗时
      //         setTimeout(() => {
      //             // 后续操作
      //             console.log("done");
      //         }, audio.duration * 1000); // audio.duration 为音频的时长单位为秒
 
 
      //     }).catch((e) => {
      //         console.log("Operation is too fast, audio play fails");
      //     });
      // }
      setTimeout(() => {
        this.$refs.audio.play();
}, 10 );
    },
    //音频暂停
    stop() {
      this.dialogVisible = false;
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
    }
}
}
</script>

